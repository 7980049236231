<template>
  <div class="about">
    <el-row :gutter="10">
      <el-col :span="24">
        <div>
          <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
          <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
        </div>
        <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
          <el-table-column  prop="name" label="角色名称">
          </el-table-column>
          <el-table-column prop="address" label="操作" width="110">
            <template #header>
              <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
            </template>
            <template #default="scope">
              <div style="margin: 5px 0">
                <el-button-group>
                  <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                  <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
                </el-button-group>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm">
        <el-form-item>
          <div style="text-align: right">
            <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
            <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
          </div>
         </el-form-item>
        <el-form-item label="角色名称" prop="name">
          <el-input  v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-tabs v-model="activeName">
          <el-tab-pane label="菜单" name="1">
<!--            <el-cascader-panel-->
<!--                placeholder=""-->
<!--                ref="cascaderHandle"-->
<!--                style="width: 99%"-->
<!--                :options="ruleList"-->
<!--                v-model="ruleForm.menu_rule"-->
<!--                :props="{-->
<!--                        label: 'name',-->
<!--                        value: 'id',-->
<!--                        children: 'children',-->
<!--                        multiple: true,-->
<!--                      }"-->
<!--                :filterable="true">-->
<!--            </el-cascader-panel>-->
              <el-tree
                  ref="treeMenu"
                  :data="ruleList"
                  show-checkbox
                  :default-expand-all="isOpen"
                  node-key="id"
                  :default-checked-keys="ruleForm.menu_rule"
                  highlight-current
                  :props="defaultProps">
              </el-tree>
          </el-tab-pane>
          <el-tab-pane label="API" name="2">
              <el-tree
                  ref="treeApi"
                  :data="apiruleList"
                  show-checkbox
                  :default-expand-all="isOpen"
                  node-key="id"
                  :default-checked-keys="ruleForm.api_rule"
                  highlight-current
                  :props="defaultProps">
              </el-tree>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/menuApi"//本页面接口

export default {
  name: 'brand',
  data(){
    return{
      activeName:'1',
      //树形
      ruleList: [],
      apiruleList: [],
      checked:false, //checkbox的值
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },//字段定义
      isOpen:false,
      //树形
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        menu_rule:[],
      },
      modeArr:[
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        tip: [
          { required: true, message: '请填写提示', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onMenu()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getroleData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    onMenu(){
      axios.getmenuData({type:1}).then((res)=>{
        console.log(res)
        this.ruleList=res.data
      }).catch((err)=>{

      })
      axios.getmenuData({type:0}).then((res)=>{
        console.log(res)
        this.apiruleList=res.data
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        menu_rule:[],
        api_rule:[],
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.menu_rule=JSON.parse(row.menu_rule)
      // this.menu_rule=row.menu_rule
      this.ruleForm.api_rule=JSON.parse(row.api_rule)
      // this.api_rule=row.api_rule
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.ruleForm.menu_rule=this.$refs.treeMenu.getCheckedKeys()
      this.ruleForm.api_rule=this.$refs.treeApi.getCheckedKeys()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddrole(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditrole(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelrole({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>